import { AxiosResponse } from 'axios';
import { flatten, isEmpty, merge } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { logPageView } from '../../analytics';
import {
  getAccountBrandSetup,
  getBrandingSetup
} from '../../clientSideServices/brand';
import { getStoreConfig } from '../../clientSideServices/store';
import {
  getCurrentUserEntitlements,
  getCurrentUserInfo,
  getMeetUserByIdentityId,
  // getUserByIdentityId,
  saveUser,
  updateUserStatus
} from '../../clientSideServices/users';
import GA from '../../components/Tracking/GA';
import {
  HUB_USER_PERMISSION,
  IAccount,
  IAddUpdateDeleteTeam,
  IBrandSetup,
  IHubSearchFilters,
  IMainState,
  IMeetUser,
  InvitationStatus,
  IProductsFilterList,
  IUser,
  IUserEntitlement,
  IVirtualBoutiqueEntitlement,
  LoginStatus
} from '../../interfaces';
import { actionAddUserInfo } from '../../redux/actions';
import { actionLoginWithValidCachedCredentialsAsync } from '../../redux/advisorHubAsyncActions';
import {
  shouldShowAdmin,
  shouldShowGallery,
  shouldShowInvites,
  shouldShowProfile,
  shouldShowTeams
} from '../../utils/entitlements';
import { getIdentityId } from '../../utils/identity';
import { PageView } from '../../utils/pageView';
import { isEmbeddedInStreamingStudio } from '../../utils/streamingstudio';
import { apigClient } from '../clientSideServices/aws';
import { hasValidSession } from '../clientSideServices/login';
import { mapUserPayloadToUser } from '../clientSideServices/user';
import {
  isInspifyTestBrand,
  MEET_ADMIN_USER,
  reqMeetUserType,
  shouldShowStorybooks
} from '../utils/hubEntitlements';
import { mapBrandSetupRes } from '../utils/mapper';
import { getPageTypeFromHashPath } from './../utils/hubPagesRoute';
import { getAssetPath, getThemeColor, ThemeColor } from './BrandStyle';
import BrandCustomStyle from './BrandStyle/BrandCustomStyle';

export enum UserEntitlementType {
  STORE_ID = 'STORE_ID',
  BRAND_ID = 'BRAND_ID',
  USER_ADMIN = 'USER_ADMIN',
  RECORDING_VISIBILITY = 'RECORDING_VISIBILITY'
}

export const HubContext = React.createContext<{
  brandId: string;
  storeId: string;
  searchFilters: IHubSearchFilters;
  page: PageView;
  guidedTour: string;
  isGuidedTourReplay: boolean;
  productsFilterList: IProductsFilterList;
  color: ThemeColor;
  assetPath: string;
  isAdmin: boolean;
  user: IUser;
  meetUser: IMeetUser;
  isEmbeddedInSsp: boolean;
  brandSetup?: IBrandSetup;
  brandSetupStep?: number;
  account?: IAccount;
  setBrandSetup: React.Dispatch<
    React.SetStateAction<{
      account?: IAccount;
      brandSetup?: IBrandSetup;
      step?: number;
    }>
  >;
  entitlements?: IVirtualBoutiqueEntitlement;
  userEntitlements?: IUserEntitlement[]; // OLD USER WONT HAVE ANY
  userRoles: IUserEntitlement['roles'];
  checkUserPermissions: (name: string) => boolean;
  chooseCheckPermissionResult: (
    userTypeCheckResult: boolean,
    entitlementCheckResult: boolean
  ) => boolean;
  isShowingSessionPage: () => boolean;
  hasPermission: (page: string) => boolean;
  setUser: (IUser) => void;
  addUpdateDeleteTeam;
  setAddUpdateDeleteTeam: (teamQuery: IAddUpdateDeleteTeam) => void;
  isStudioOwnerUser: () => boolean;
  subPage: string;
  setSubPage: (string) => void;
  setGuidedTour: (tour: string) => void;
  setIsGuidedTourReplay: (replay: boolean) => void;
  checkUserHasEntitlement: (entitlementType: string) => boolean;
}>(undefined);

const HubContextContainer = ({
  brandId,
  searchFilters,
  children,
  storeId,
  brandSetup: initialBrandSetup
}: {
  brandId: string;
  brandSetup?: IBrandSetup;
  searchFilters: IHubSearchFilters;
  children: React.ReactNode;
  storeId: string;
}) => {
  const dispatch = useDispatch();
  const color = getThemeColor(brandId);
  const assetPath = getAssetPath(brandId);
  const [page, setPage] = React.useState(PageView.HUB_HOME);
  const [subPage, setSubPage] = React.useState('');
  const [guidedTour, setGuidedTour] = React.useState('');
  const [isGuidedTourReplay, setIsGuidedTourReplay] = React.useState(false);
  const [productsFilterList, setProductsFilterList] =
    React.useState<IProductsFilterList>();
  const [entitlements, setEntitlements] =
    React.useState<IVirtualBoutiqueEntitlement>(undefined);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isEmbeddedInSsp, setIsEmbeddedInSsp] = React.useState(false);
  const [user, setUser] = React.useState<IUser>();

  const [userEntitlements, setUserEntitlements] =
    React.useState<IUserEntitlement[]>();
  const [meetUser, setMeetUser] = React.useState<IMeetUser>();

  const [{ account, brandSetup, step }, setAccountBrandSetup] = React.useState<{
    account?: IAccount;
    brandSetup?: IBrandSetup;
    step?: number;
  }>({ brandSetup: initialBrandSetup });

  const [hubColor, setHubColor] = useState<ThemeColor>(color);

  const fetchBrandSetup = () => {
    getAccountBrandSetup()
      .then(({ data }) => {
        const brandSetup = mapBrandSetupRes(data.brand);
        setAccountBrandSetup((prev) => {
          return {
            account: data.account,
            brandSetup: merge(brandSetup, prev.brandSetup),
            step: data.step
          };
        });
      })
      .catch(() => {
        console.log('NOT OWNER');
      });
  };

  useEffect(() => {
    if (!isEmpty(brandSetup)) {
      setHubColor((prev) => ({
        ...prev,
        accent: brandSetup.theme?.color?.secondary || prev.accent
      }));
    }
  }, [brandSetup]);

  const loginStatus = useSelector(
    (state: IMainState) => state.clientState.hub?.loginStatus
  );
  const [addUpdateDeleteTeam, setAddUpdateDeleteTeam] =
    React.useState<IAddUpdateDeleteTeam>();
  const queryClient = new QueryClient();

  const userRoles = useMemo(() => {
    return flatten(userEntitlements?.map((entl) => entl?.roles));
  }, [userEntitlements]);

  const checkUserPermissions = (name: string) => {
    if (!userEntitlements) {
      return false;
    }
    return userEntitlements.some((entitlement) =>
      entitlement?.roles?.some((role) => {
        return role?.permissions?.some((perm) => perm.name === name);
      })
    );
  };

  const isStudioOwnerUser = () => {
    if (!userEntitlements) {
      return false;
    }
    return userEntitlements.some((entitlement) =>
      entitlement?.roles?.some((role) => {
        return role?.name === 'STUDIO_OWNER';
      })
    );
  };

  const checkUserHasEntitlement = (entitlementType: string) => {
   
    const entitlements = user?.entitlements || [];
    return entitlements.some((en) => en.entitlementType === entitlementType);
  };

  const hasPermission = (permission) => {
    switch (permission) {
      case HUB_USER_PERMISSION.STORYBOOK: {
        const hasSBPermission = chooseCheckPermissionResult(
          shouldShowStorybooks({
            storeId,
            brandId,
            permissions: meetUser?.permissions,
            userType: user?.userType
          }),
          checkUserPermissions(HUB_USER_PERMISSION.STORYBOOK)
        );
        return hasSBPermission;
      }
      case HUB_USER_PERMISSION.ASSET_LIBRARY: {
        return checkUserPermissions(HUB_USER_PERMISSION.ASSET_LIBRARY);
      }
      case HUB_USER_PERMISSION.NUDGE: {
        return chooseCheckPermissionResult(
          shouldShowInvites(user?.userType, entitlements?.meetOnly),
          checkUserPermissions(HUB_USER_PERMISSION.NUDGE)
        );
      }
      case HUB_USER_PERMISSION.USER_MANAGEMENT: {
        return chooseCheckPermissionResult(
          shouldShowAdmin(meetUser?.permissions, user?.userType, isAdmin),
          checkUserPermissions(HUB_USER_PERMISSION.USER_MANAGEMENT)
        );
      }
      case HUB_USER_PERMISSION.BRANDING: {
        return checkUserPermissions(HUB_USER_PERMISSION.BRANDING);
      }
      case HUB_USER_PERMISSION.TEAM: {
        return chooseCheckPermissionResult(
          shouldShowTeams({
            brandId,
            permissions: meetUser?.permissions,
            userType: user?.userType,
            storeId
          }),
          checkUserPermissions(HUB_USER_PERMISSION.TEAM)
        );
      }
      case HUB_USER_PERMISSION.PROFILE: {
        return chooseCheckPermissionResult(
          shouldShowProfile(meetUser?.permissions, user?.userType),
          checkUserPermissions(HUB_USER_PERMISSION.PROFILE)
        );
      }
      case HUB_USER_PERMISSION.GALLERY: {
        return chooseCheckPermissionResult(
          shouldShowGallery(user?.userType, entitlements?.meetOnly),
          checkUserPermissions(HUB_USER_PERMISSION.GALLERY)
        );
      }
      case HUB_USER_PERMISSION.SCENE: {
        return chooseCheckPermissionResult(
          shouldShowStorybooks({
            storeId,
            brandId,
            permissions: meetUser?.permissions,
            userType: user?.userType
          }),
          checkUserPermissions(HUB_USER_PERMISSION.SCENE)
        );
      }
      case HUB_USER_PERMISSION.SCENE_TEMPLATE: {
        return (
          isInspifyTestBrand(brandId) ||
          checkUserPermissions(HUB_USER_PERMISSION.SCENE_TEMPLATE)
        );
      }
      default:
        return false;
    }
  };
  const chooseCheckPermissionResult = (
    userTypeCheckResult,
    entitlementCheckResult
  ) => {
    if (isEmpty(userEntitlements)) {
      return userTypeCheckResult;
    }
    return entitlementCheckResult;
  };

  const isShowingSessionPage = () => {
    return (
      page === PageView.HUB_SESSION_OVERVIEW ||
      page === PageView.HUB_HOME ||
      (page === PageView.HUB_ASSET_LIBRARY &&
        !hasPermission(HUB_USER_PERMISSION.ASSET_LIBRARY)) ||
      (page === PageView.HUB_STORYBOOK &&
        !hasPermission(HUB_USER_PERMISSION.STORYBOOK)) ||
      (page === PageView.HUB_NUDGES &&
        !hasPermission(HUB_USER_PERMISSION.NUDGE)) ||
      (page === PageView.HUB_USERMANAGEMENT &&
        !hasPermission(HUB_USER_PERMISSION.USER_MANAGEMENT)) ||
      (page === PageView.HUB_BRANDING &&
        !hasPermission(HUB_USER_PERMISSION.BRANDING)) ||
      (page === PageView.HUB_TEAMS &&
        !hasPermission(HUB_USER_PERMISSION.TEAM)) ||
      (page === PageView.HUB_ACCOUNTSETTINGS &&
        !hasPermission(HUB_USER_PERMISSION.PROFILE)) ||
      (page === PageView.HUB_GALLERY &&
        !hasPermission(HUB_USER_PERMISSION.GALLERY)) ||
      (page === PageView.HUB_SCENE &&
        !hasPermission(HUB_USER_PERMISSION.SCENE)) ||
      (page === PageView.HUB_SCENE_TEMPLATE &&
        !hasPermission(HUB_USER_PERMISSION.SCENE_TEMPLATE))
    );
  };

  React.useEffect(() => {
    setIsEmbeddedInSsp(isEmbeddedInStreamingStudio());
  }, []);

  React.useEffect(() => {
    if (initialBrandSetup) {
      return;
    }
    if (storeId) {
      getStoreConfig(storeId)
        .then((result) => {
          setProductsFilterList({
            productBlacklist: result.data?.productBlacklist,
            productWhitelist: result.data?.productWhitelist
          });
          setEntitlements(result.data?.entitlement);
        })
        .catch((e) => {
          console.error(`error fetching store config for ${storeId}`, e);
        });
    }
  }, [storeId]);

  React.useEffect(() => {
    const hashChangeListener = () => {
      const [hash] = location.hash.split('?');
      const newPage = getPageTypeFromHashPath(hash.replace('#/', ''));
      if (page !== newPage) {
        logPageView(storeId, newPage);
        setPage(newPage);
      }
    };

    if (location.hash) hashChangeListener();
    window.addEventListener('hashchange', hashChangeListener);
    hasValidSession().then((isValid) => {
      if (isValid && loginStatus !== LoginStatus.LOGGED_IN) {
        dispatch(actionLoginWithValidCachedCredentialsAsync());
      }
    });

    return () => {
      window.removeEventListener('hashchange', hashChangeListener);
    };
  }, [page]);

  useEffect(() => {
    if (loginStatus !== LoginStatus.LOGGED_IN || isEmpty(initialBrandSetup)) {
      return;
    }
    if (
      userRoles?.some((r) => r?.name?.includes('OWNER')) // brand owner
    ) {
      fetchBrandSetup();
      return;
    } else {
      getBrandingSetup(initialBrandSetup?.brandUrl)
        .then(({ data }) => {
          const brandingData = mapBrandSetupRes(data);
          setAccountBrandSetup((prev) => ({
            ...prev,
            brandSetup: brandingData
          }));
        })
        .catch((e) => {
          console.error('error fetching branding setup', e);
        });
    }
  }, [loginStatus, userRoles]);
  const loadUserById = async (id: string): Promise<any> => {
    const client = await apigClient();
    const path = `/users/v3/web/${encodeURIComponent(id)}`;
    // interface ApiResponse {
    //   data: {
    //     body: string;
    //   };
    // }
    const userResponse: AxiosResponse<any> = await client.invokeApi(
      {},
      path,
      'GET',
      {}
    );
    // const parsedBody = JSON.parse(userResponse.data.body);
    return mapUserPayloadToUser(userResponse.data);
  };
  React.useEffect(() => {
    if (storeId) {
      getCurrentUserEntitlements()
        .then((data) => {
          setUserEntitlements(data);
        })
        .catch((err) => {
          console.log(err);
        });
      getCurrentUserInfo().then((userInfo) => {
        if (
          userInfo?.attributes?.['custom:onboarding_status'] ===
          'invitation_pending'
        ) {
          return updateUserStatus('accepted');
        }
      });
      loadUserById(getIdentityId())
        .then((data) => {
          if (data) {
            setUser(data);
            dispatch(
              actionAddUserInfo({
                id: data.id,
                alias: data.alias,
                email: data.email,
                firstName: data.first_name,
                lastName: data.last_name
              })
            );
          }
          const _isAdmin = data.entitlements.some(
            (entitlement) =>
              entitlement.entitlementType === UserEntitlementType.USER_ADMIN &&
              entitlement.uuid === storeId
          );
          setIsAdmin(_isAdmin || data.userType === MEET_ADMIN_USER);
          if (reqMeetUserType.includes(data.userType)) {
            getMeetUserByIdentityId(getIdentityId())
              .then((data) => {
                setMeetUser(data);
              })
              .catch((err) => {
                console.log('Failed to get meet user id', getIdentityId());
                console.log(err);
              });
          }
          if (data.statusText === InvitationStatus.INVITATION_PENDING) {
            const _user = { ...data, statusText: InvitationStatus.ACCEPTED };
            saveUser(_user);
          }
        })
        .catch((err) => {
          console.log('Failed to get user id', getIdentityId());
          console.log(err);
        });
    } else {
      // reset state value
      setUserEntitlements([]);
      setUser(null);
      setIsAdmin(false);
      setMeetUser(null);
    }
  }, [storeId]);

  return (
    <QueryClientProvider client={queryClient}>
      <HubContext.Provider
        value={{
          brandId,
          storeId,
          searchFilters,
          page,
          productsFilterList,
          entitlements,
          userEntitlements,
          checkUserPermissions,
          chooseCheckPermissionResult,
          isShowingSessionPage,
          hasPermission,
          userRoles,
          brandSetup,
          brandSetupStep: step,
          account,
          setBrandSetup: setAccountBrandSetup,
          color: hubColor,
          assetPath,
          isAdmin,
          user,
          meetUser,
          isEmbeddedInSsp,
          setUser,
          addUpdateDeleteTeam,
          setAddUpdateDeleteTeam,
          isStudioOwnerUser,
          subPage,
          setSubPage,
          guidedTour,
          setGuidedTour,
          isGuidedTourReplay,
          setIsGuidedTourReplay,
          checkUserHasEntitlement
        }}
      >
        <GA storeId={storeId}>{children}</GA>
        {!isEmpty(brandSetup) && <BrandCustomStyle brandSetup={brandSetup} />}
      </HubContext.Provider>
    </QueryClientProvider>
  );
};

export default HubContextContainer;
